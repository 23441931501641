import { combineReducers } from '@reduxjs/toolkit';
import AuthentificationReducer from '../app/features/authentification/infrastructure/store';
import ProfileReducer from '../app/features/private/profile/infrastructure/store';
import GlobalReducer from './app.store';
import PrivateReducer from '../app/features/private/infrastructure/store';
import EventsReducer from '../app/features/private/event/infrastructure/store';
import BroadcastReducer from '../app/features/private/broadcast/infrastructure/store';
import ConnectReducer from '../app/features/private/connect/infrastructure/store';
import DocumentReducer from '../app/features/private/Documents/infrastructure/store';
import NotificationReducer from '../app/features/private/notification/infrastructure/store';
import FiltersReducer from 'app/features/private/connect/views/explore/filters/store';

const rootReducer = combineReducers({
  authentification: AuthentificationReducer,
  profile: ProfileReducer,
  global: GlobalReducer,
  private: PrivateReducer, // TODO to delete
  events: EventsReducer,
  broadcast: BroadcastReducer,
  connect: ConnectReducer,
  document: DocumentReducer,
  notification: NotificationReducer,
  filters: FiltersReducer
});
export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
