import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPaginationConfig } from 'app/shared/types/pagination-config.type';

export interface FilterState {
  query: any;
  paginationConfig: IPaginationConfig;
}

const initialState: FilterState = {
  query: {},
  paginationConfig: { page: 1, limit: 10 },
};

const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setFilters(state, action: PayloadAction<{ query: any; paginationConfig: IPaginationConfig }>) {
      state.query = action.payload.query;
      state.paginationConfig = action.payload.paginationConfig;
    },
  },
});

export const { setFilters } = filtersSlice.actions;
const FiltersReducer = filtersSlice.reducer
export default FiltersReducer;