import { FC, useState, useRef, useEffect } from 'react';
import SearchSpecialties from './search-specialties/search-specialties';
import { useConnectApi } from '../../../hooks/useConnectApi';
import { FilterRequestModel } from '../../../infrastructure/domain/FilterRequest.model';
import { useDebouncedCallback } from 'use-debounce';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectConnectLimit, selectConnectPage } from '../../../infrastructure/selectors';
import { IPaginationConfig } from '../../../../../../shared/types/pagination-config.type';
import {
  Box,
  Text,
  Input,
  AlertDialog,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Button,
  Switch,
  FormControl,
  FormLabel,
  Flex,
  List,
  ListItem,
} from '@chakra-ui/react';
import './filters.scss';
import Select from 'react-select';
import { AddressResponse } from '../../../infrastructure/domain/Address.model';
import { setFilters } from './store';
import { RootState } from 'store/rootReducer';

interface PublicFiltersProps {}

const PublicFilters: FC<PublicFiltersProps> = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const [errorName, setErrorName] = useState<boolean>(false);
  const [filteredCities, setFilteredCities] = useState<AddressResponse[]>([]);
  const [cities, setCities] = useState<AddressResponse[]>([]);
  const { isOpen, onClose } = useDisclosure();

  const dataGeolocation = [
    { value: 0, label: translate('connect.explore.filter.range.allDistance.option') },
    { value: 3, label: '< 3 km' },
    { value: 5, label: '< 5 km' },
    { value: 10, label: '< 10 km' },
    { value: 15, label: '< 15 km' },
    { value: 25, label: '< 25 km' },
    { value: 50, label: '< 50 km' },
    { value: 75, label: '< 75 km' },
  ];

  const cancelRef = useRef(null);
  const { query: globalQuery } = useSelector((state: RootState) => state.filters);
  const [query, setQuery] = useState<FilterRequestModel>(globalQuery);
  const page = useSelector(selectConnectPage);
  const limit = useSelector(selectConnectLimit);
  const { filterUsers, getAllUsers, getAdresses } = useConnectApi();
  const debounced = useDebouncedCallback(filterUsers, 1000);
  const [useFilter, setUseFilter] = useState<boolean>(
    !Object.values(query).every((value) => value == null || value === 0 || value === '')
  );
  const [selectedCity, setSelectedCity] = useState<AddressResponse>();
  useEffect(() => {
    setQuery(globalQuery);
  }, [globalQuery]);

  useEffect(() => {
    const fetchAddresses = async () => {
      const result = await getAdresses();
      setCities(result);
    };
    fetchAddresses();
  }, []);

  const onNameChange = (name: string) => {
    setErrorName(false);
    setQuery({
      ...query,
      name,
    });
  };

  const onSpecialtiesChange = (specialties: string[]) => {
    setQuery({
      ...query,
      specialties,
    });
  };

  const onApplyFiltersClick = () => {
    const paginationConfig: IPaginationConfig = {
      page,
      limit,
    };
    
    const newQuery = { ...query };
    if (newQuery.postalCode && !cities.some((city) => city.postalCode === newQuery.postalCode)) {
      return;
    } else if (!newQuery.postalCode) {
      newQuery.latitude = null;
      newQuery.longitude = null;
    }
    if(!newQuery.postalCode){
      setSelectedCity({ city:'', id: '', latitude: 0, longitude: 0, postalCode: '' })
    }
    if (newQuery?.name !== '' && newQuery?.name?.length < 3) {
      setErrorName(true);
    } else if (newQuery?.specialties?.length || newQuery?.distance || newQuery?.name || newQuery?.postalCode) {
      dispatch(setFilters({ query: newQuery, paginationConfig }));
      debounced(newQuery, paginationConfig);
    } else {
      dispatch(setFilters({ query: newQuery, paginationConfig }));
      getAllUsers(paginationConfig, false);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      onApplyFiltersClick();
    }
  };

  const handlePostalCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setQuery({
      ...query,
      postalCode: value,
    });
    
    if (value.length > 0) {
      const filtered = cities.filter((address) => address.postalCode.startsWith(value));

      setFilteredCities(filtered.length ? filtered : []);
    } else {
      setFilteredCities([]);
    }
  };

  const handleCitySelect = (city: AddressResponse) => {
    const selectedCity = cities.find((address: any) => address.postalCode === city.postalCode);
    setSelectedCity(selectedCity);
    if (selectedCity) {
      setQuery({
        ...query,
        postalCode: selectedCity.postalCode,
        longitude: selectedCity.longitude,
        latitude: selectedCity.latitude,
      });
      setFilteredCities([]);
    }
  };

  return (
    <>
      <div className="custom-container pb-2">
        <Box
          bg="white"
          display={'flex'}
          flexDirection={window.screen.width > 550 ? 'row' : 'column'}
          justifyContent={'space-between'}
          flexWrap={'wrap'}
        >
          <Text as="b" className="title">
            {translate('connect.explore.filter.publicFilterTitle')}
          </Text>
          <FormControl display="flex" alignItems="center" width={'max-content'} className="use-filter-switch-box">
            <FormLabel htmlFor="filters" mb="0">
              {translate('connect.explore.filter.useFilter')}
            </FormLabel>
            <span
              className={`${
                useFilter ? 'yes-label' : 'no-label'
              } switch-icon-left use-filter-custom-label text-capitalize`}
            >
              {useFilter ? translate('common.yes') : translate('common.no')}
            </span>
            <Switch onChange={(e) => setUseFilter(e.target.checked)} id="filters" />
          </FormControl>
        </Box>
        <Box
          bg="white"
          rounded="md"
          display={useFilter ? 'flex' : 'none'}
          style={{ alignItems: 'center', flexWrap: 'wrap' }}
        >
          <Flex direction={'column'}>
            <Text as="b" className="title" mt={'10px'}>
              {translate('connect.explore.filter.lookingForTherapistName')}
            </Text>
            <Box mr={'10px'} mt={'10px'}>
              <Input
                size="sm"
                name="name"
                value={query.name}
                placeholder={translate('connect.explore.filter.lookingForTherapistName_placeholder') || ''}
                type="text"
                onChange={(e) => onNameChange(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              {errorName && (
                <Text as={'b'} fontSize={'x-small'} color={'#e53e3e'}>
                  {translate('connect.explore.filter.lookingForTherapistName.error')}
                </Text>
              )}
            </Box>
          </Flex>
          <Flex direction={'column'}>
            <Text as="b" className="title" mt={'10px'}>
              {translate('connect.explore.filter.followingSpecialities')}
            </Text>
            <Box mr={'10px'} mt={'10px'}>
              <SearchSpecialties
                onChange={(specialties) => onSpecialtiesChange(specialties)}
                width="100%"
                size="sm"
                value={query.specialties ?? []}
                handleKeyDown={handleKeyDown}
              />
            </Box>
          </Flex>
          <Flex direction={'column'}>
            <Text as="b" className="title" mt={'10px'}>
              {translate('connect.explore.filter.range')}
            </Text>
            <Box mr={'10px'} mt={'10px'}>
              <Select
                isClearable
                options={dataGeolocation}
                value={dataGeolocation.find((dis) => dis.value === query.distance)}
                placeholder={translate('connect.explore.filter.range_placeholder')}
                onChange={(e) => setQuery({ ...query, distance: e && e.value })}
                onKeyDown={handleKeyDown}
              />
            </Box>
          </Flex>
          <Flex direction={'column'}>
            <Text as="b" className="title" mt={'10px'}>
              {translate('common.postalCode')}
            </Text>
            <Box mr={'10px'} mt={'10px'} position={'relative'}>
              <Flex direction={'row'} gap={'1rem'} alignItems={'center'} flexWrap={'wrap'}>
                <Input
                  size="sm"
                  name="postalCode"
                  placeholder={translate('connect.explore.filter.nearCity_placeholder') || ''}
                  type="text"
                  value={query.postalCode}
                  onChange={handlePostalCodeChange}
                  onKeyDown={handleKeyDown}
                  flex="1" 
                />
                {selectedCity && selectedCity.postalCode && (
                  <Text className="w-full" flex="none" >
                    {`${selectedCity.city}${
                      query.distance
                        ? `(${dataGeolocation.find((loc: any) => loc.value === query.distance)?.label})`
                        : ''
                    }`}
                  </Text>
                )}
              </Flex>
              {filteredCities.length > 0 && (
                <List
                  position="absolute"
                  zIndex="1"
                  bg="white"
                  border="1px solid"
                  borderColor="gray.200"
                  borderRadius="md"
                  mt="1"
                  width="100%"
                  boxShadow="md"
                >
                  {filteredCities.map((city, index) => (
                    <ListItem
                      key={index}
                      px="3"
                      py="2"
                      cursor="pointer"
                      _hover={{ bg: 'gray.100' }}
                      onClick={() => handleCitySelect(city)}
                    >
                      {city.city}
                    </ListItem>
                  ))}
                </List>
              )}
            </Box>
          </Flex>
        </Box>
        <Box display={useFilter ? 'block' : 'none'} py="0.5rem" style={{ textAlign: 'right' }}>
          <Button
            size="sm"
            flexGrow={0}
            bg={'primary.600'}
            color={'white'}
            rounded={'md'}
            _hover={{
              transform: 'translateY(-2px)',
              boxShadow: 'lg',
            }}
            onClick={onApplyFiltersClick}
          >
            {translate('common.apply_filters')}
          </Button>
        </Box>
      </div>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {translate('common.not_supported_browser')}
            </AlertDialogHeader>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                {translate('connect.explore.buttons.close')}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default PublicFilters;
