import { useDispatch } from 'react-redux';
import {
  acceptInvitationApi,
  cancelInvitationApi,
  checkConnectionApi,
  declineInvitationApi,
  deleteConnectionApi,
  filterUsersApi,
  getAdressesApi,
  getAllUsersApi,
  getDeclinedInvitationsApi,
  getDeclinedInvitationsAsTargetApi,
  getMyNetworkUsersApi,
  getPendingInvitationsApi,
  getPendingInvitationsAsTargetApi,
  getUserByIdApi,
  sendInvitationApi,
} from '../infrastructure/api';
import {
  setActiveUser,
  setConnectLoading,
  setDeclinedInvitations,
  setDeclinedInvitationsAsTarget,
  setPendingInvitations,
  setPendingInvitationsAsTarget,
  setConnectTotalPage,
  setUsers,
} from '../infrastructure/store';
import { IPaginationConfig } from '../../../../shared/types/pagination-config.type';

interface UseConnectApiReturn {
  getAllUsers: (paginationConfig: IPaginationConfig, isPublic: boolean) => Promise<any>;
  getMyNetworkUsers: (paginationConfig: IPaginationConfig, query?: any) => Promise<any>;
  getUserById: (id: string) => Promise<void>;
  filterUsers: (query: any, paginationConfig: IPaginationConfig) => Promise<any>;
  getPendingInvitations: () => Promise<void>;
  getDeclinedInvitations: () => Promise<void>;
  getPendingInvitationsAsTarget: () => Promise<void>;
  getDeclinedInvitationsAsTarget: () => Promise<void>;
  acceptInvitation: (invitationId: string) => Promise<any>;
  declineInvitation: (invitationId: string) => Promise<any>;
  cancelInvitation: (invitationId: string) => Promise<any>;
  sendInvitation: (userId: string) => Promise<any>;
  checkConnection: (userId: string) => Promise<any>;
  deleteConnection: (userId: string) => Promise<any>;
  getAdresses: () => Promise<any>;
}

export const useConnectApi = (): UseConnectApiReturn => {
  const dispatch = useDispatch();

  const getAllUsers: UseConnectApiReturn['getAllUsers'] = async (
    paginationConfig: IPaginationConfig,
    isPublic: boolean
  ) => {
    dispatch(setConnectLoading(true));
    const result = await getAllUsersApi(paginationConfig, isPublic);
    dispatch(setUsers(result?.items));
    dispatch(setConnectTotalPage(result?.meta?.totalPages));
    dispatch(setConnectLoading(false));
    return result;
  };
  const getAdresses: UseConnectApiReturn['getAdresses'] = async () => {
    return await getAdressesApi();
  };

  const getMyNetworkUsers: UseConnectApiReturn['getMyNetworkUsers'] = async (
    paginationConfig: IPaginationConfig,
    query: any
  ) => {
    dispatch(setConnectLoading(true));
    const result = await getMyNetworkUsersApi(paginationConfig, query);
    dispatch(setUsers(result?.items));
    dispatch(setConnectTotalPage(result?.meta?.totalPages));
    dispatch(setConnectLoading(false));
    return result;
  };

  const filterUsers: UseConnectApiReturn['filterUsers'] = async (query: any, paginationConfig: IPaginationConfig) => {
    dispatch(setConnectLoading(true));
    const result = await filterUsersApi(query, paginationConfig);
    dispatch(setUsers(result?.items));
    dispatch(setConnectTotalPage(result?.meta?.totalPages));
    dispatch(setConnectLoading(false));
    return result;
  };

  const getUserById: UseConnectApiReturn['getUserById'] = async (id: string) => {
    dispatch(setConnectLoading(true));
    const result = await getUserByIdApi(id);
    dispatch(setActiveUser(result));
    dispatch(setConnectLoading(false));

    return result;
  };

  const getPendingInvitations: any = async () => {
    dispatch(setConnectLoading(true));
    const result = await getPendingInvitationsApi();
    dispatch(setPendingInvitations(result));
    dispatch(setConnectLoading(false));
  };

  const getDeclinedInvitations: any = async () => {
    dispatch(setConnectLoading(true));
    const result = await getDeclinedInvitationsApi();
    dispatch(setDeclinedInvitations(result));
    dispatch(setConnectLoading(false));
  };

  const getPendingInvitationsAsTarget: any = async () => {
    dispatch(setConnectLoading(true));
    const result = await getPendingInvitationsAsTargetApi();
    dispatch(setPendingInvitationsAsTarget(result));
    dispatch(setConnectLoading(false));
  };

  const getDeclinedInvitationsAsTarget: any = async () => {
    dispatch(setConnectLoading(true));
    const result = await getDeclinedInvitationsAsTargetApi();
    dispatch(setDeclinedInvitationsAsTarget(result));
    dispatch(setConnectLoading(false));
  };

  const cancelInvitation = async (invitationId: string): Promise<any> => {
    return cancelInvitationApi(invitationId);
  };

  const acceptInvitation = async (invitationId: string): Promise<any> => {
    return acceptInvitationApi(invitationId);
  };

  const declineInvitation = async (invitationId: string): Promise<any> => {
    return declineInvitationApi(invitationId);
  };

  const sendInvitation = async (userId: string): Promise<any> => {
    return sendInvitationApi(userId);
  };

  const deleteConnection = async (userId: string): Promise<any> => {
    return deleteConnectionApi(userId);
  };

  const checkConnection = async (userId: string): Promise<any> => {
    return checkConnectionApi(userId);
  };

  return {
    getAllUsers,
    getAdresses,
    getUserById,
    filterUsers,
    getDeclinedInvitations,
    getDeclinedInvitationsAsTarget,
    getPendingInvitations,
    getPendingInvitationsAsTarget,
    acceptInvitation,
    declineInvitation,
    cancelInvitation,
    sendInvitation,
    checkConnection,
    deleteConnection,
    getMyNetworkUsers,
  };
};
