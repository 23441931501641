import { FC, useEffect } from 'react';
import { Flex } from '@chakra-ui/react';
import { useConnectApi } from '../../../hooks/useConnectApi';
import { useSelector } from 'react-redux';
import { selectAllUsers, selectConnectLoading, selectConnectPage, selectConnectTotalPage } from '../../../infrastructure/selectors';
import SkeletonVVTIV from '../../../../../../shared/components/skeleton/skeleton';
import { SkeletonShape } from '../../../../../../shared/enums/skeletonShape';
import { IPaginationConfig } from '../../../../../../shared/types/pagination-config.type';
import UserProfile from '../user-card/user-filter-card';
import PaginationVV from '../../../../../../shared/components/pagination/PaginationVV';
import NoDataFound from '../../../../components/no-data-found/NoDataFound';
import { RootState } from 'store/rootReducer';

interface UsersListProps {
  myNetwork: boolean;
  isPublic: boolean;
}

const UsersList: FC<UsersListProps> = ({ myNetwork, isPublic }) => {
  const { filterUsers, getAllUsers, getMyNetworkUsers } = useConnectApi();
  const isLoading = useSelector(selectConnectLoading);
  const usersList = useSelector(selectAllUsers);
  const totalPages = useSelector(selectConnectTotalPage);
  const page = useSelector(selectConnectPage);
  const { query: globalQuery } = useSelector((state: RootState) => state.filters);


  useEffect(() => {
    getUsers(1, 10);
  }, [myNetwork]);

  const getUsers = (myPage: number, limit: number) => {
    const paginationConfig: IPaginationConfig = {
      page: myPage,
      limit,
    };
    if (myNetwork) {
      getMyNetworkUsers(paginationConfig, isPublic);
      return;
    }
    if(globalQuery)
    {
      filterUsers(globalQuery, paginationConfig);
      return;
    }
    getAllUsers(paginationConfig, isPublic);
  };

  const displaySkeleton = (item: number) => {
    return <SkeletonVVTIV key={item} type={SkeletonShape.RECTANGLE} width="270px" height="450px" />;
  };

  return (
    <Flex flexDir="column" gap="1rem" mt="2rem">
      {isLoading && [1, 2, 3, 4, 5, 6].map((item) => displaySkeleton(item))}
      {!isLoading && usersList.length === 0 && <NoDataFound />}
      {!isLoading && usersList?.map((user: any) => <UserProfile key={user.id} user={user} />)}
      {usersList?.length > 0 && (
        <PaginationVV page={page} lastPage={totalPages} apiCall={(page: number) => getUsers(page, 10)} />
      )}
    </Flex>
  );
};

export default UsersList;
