import { Avatar, Button, IconButton, Tooltip } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useColorModeValue } from '@chakra-ui/color-mode';
import { Box, Container, Flex, Stack, Text, Center } from '@chakra-ui/layout';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { selectActiveUser } from 'app/features/private/connect/infrastructure/selectors';
import { baseURL } from 'app/shared/utils/client';
import BackToDashboard from 'app/shared/components/back-to-dashboard/back-to-dashboard';
import LanguageSelect from 'app/shared/components/language-select/LanguageSelect';
import { FaSignInAlt, FaPhoneAlt } from 'react-icons/fa';
import './ProfileHeaderPublic.scss';

interface ProfileHeaderProps {
  isPublic?: boolean;
}

const ProfileHeader: FC<ProfileHeaderProps> = ({ isPublic }) => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const activeUser = useSelector(selectActiveUser);

  const getProfileInfo = () => {
    return (
      <Stack alignItems="center" justifyContent={'center'} textAlign={{ base: 'center',sm: 'start', md: 'start', lg: 'start'}}>
        <Box>
          <Text color="white" fontSize="32" >
            {activeUser?.firstName} {activeUser?.lastName}
          </Text>
          {activeUser?.jobTitle && (
            <Text color="white" fontSize="26">
              {activeUser?.jobTitle}
            </Text>
          )}
          {activeUser?.phoneNumber && (
            <Button
              leftIcon={<FaPhoneAlt />}
              borderRadius="md"
              width="200px"
              textAlign="center"
              px="10"
              py="1"
              bgColor="white"
            >
              <Text color="gray.600">{activeUser?.phoneNumber || translate('connect.explore.phone.notAvailable')}</Text>
            </Button>
          )}
          <Text color="white" fontSize="18" maxWidth="200" fontWeight="300" marginTop="5">
            {activeUser?.professionalAddress}
          </Text>
          <Text color="white" fontSize="15">
            {activeUser?.type}
          </Text>
        </Box>
      </Stack>
    );
  };

  return (
    <Box
      bg={useColorModeValue('primary.600', 'gray.900')}
      width="100%"
      height={{ lg: '290px', md: '250px', sm: '290px' }}
      className="header-public-profil-details"
    >
      <Container h="100%" width={'100%'} maxW="100%">
        <Flex width={'100%'} justify="space-between" align="center" pt="0.8rem">
          <BackToDashboard
            label="header.back_to_search"
            redirection="/zoeken"
            user={`${activeUser?.firstName} ${activeUser?.lastName}`}
          />

          <Stack direction="row">
            <LanguageSelect />
            <Tooltip label={translate('authentification.login.form.button.login.label')}>
              <IconButton
                isRound
                variant="solid"
                fontSize="20px"
                icon={<FaSignInAlt />}
                onClick={() => navigate('/login')}
                aria-label={translate('authentification.login.form.button.login.label')}
              />
            </Tooltip>
          </Stack>
        </Flex>

        <Flex
          direction={{ base: 'column-reverse', sm: 'row-reverse', md: 'row-reverse', lg: 'column' }}
          align={{ base: 'center', sm: 'center', md: 'center', lg: 'start' }}
          justify={{ base: 'center', sm: 'center', md: 'center', lg: 'center' }}
          mt={6}
          gap={{ base: 3, sm: 3, md: 4, lg: '4' }}
        >
          <Stack h="9rem" ml={'1.2rem'} justify={'center'} align={{ sm: 'center', md: 'center'}} textAlign={{ lg: 'start', md: 'center', sm: 'center' }}>
            {getProfileInfo()}
          </Stack>
          <Box mx={{ lg: 'auto' }}>
            <Avatar
              bg="rgba(99, 83, 59, 1)"
              src={activeUser?.profileCoverImage ? `${baseURL}/files/${activeUser?.profileCoverImage}` : ''}
              name={`${activeUser?.firstName} ${activeUser?.lastName}`}
              size="2xl"
              boxShadow="lg"
            />
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default ProfileHeader;
