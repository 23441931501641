import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit';

export interface ConnectState {
  loading: boolean;
  users: any[];
  activeUser: any;
  pendingInvitations: [];
  declinedInvitations: [];
  pendingInvitationsAsTarget: [];
  declinedInvitationsAsTarget: [];
  totalPage: number;
  page: number;
  limit: number;
  error: any;
}

export const initialState: ConnectState = {
  loading: false,
  users: [],
  activeUser: null,
  pendingInvitations: [],
  declinedInvitations: [],
  pendingInvitationsAsTarget: [],
  declinedInvitationsAsTarget: [],
  totalPage: 1,
  page: 1,
  limit: 10,
  error: null,
};

const ConnectSlice: Slice<ConnectState> = createSlice({
  name: 'connect',
  initialState,
  reducers: {
    setUsers: (state, action: PayloadAction<any>) => {
      state.users = action.payload;
    },
    setActiveUser: (state, action: PayloadAction<any>) => {
      state.activeUser = action.payload;
    },
    setPendingInvitations: (state, action: PayloadAction<any>) => {
      state.pendingInvitations = action.payload;
    },
    setDeclinedInvitations: (state, action: PayloadAction<any>) => {
      state.declinedInvitations = action.payload;
    },
    setPendingInvitationsAsTarget: (state, action: PayloadAction<any>) => {
      state.pendingInvitationsAsTarget = action.payload;
    },
    setDeclinedInvitationsAsTarget: (state, action: PayloadAction<any>) => {
      state.declinedInvitationsAsTarget = action.payload;
    },
    startLoading: (state) => {
      state.loading = true;
    },
    setConnectLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setConnectTotalPage: (state, action: PayloadAction<number>) => {
      state.totalPage = action.payload;
    },
    setConnectPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setConnectLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    resetPagination: (state) => {
      state.page = 1;
      state.limit = 10;
      state.users = [];
    },
    setError: (state, action: PayloadAction<Error>) => {
      state.error = action.payload;
    },
  },
});

export const {
  setUsers,
  setActiveUser,
  setPendingInvitations,
  setDeclinedInvitations,
  setPendingInvitationsAsTarget,
  setDeclinedInvitationsAsTarget,
  startLoading,
  setConnectLoading,
  setConnectTotalPage,
  setConnectPage,
  setConnectLimit,
  resetPagination,
  setError,
} = ConnectSlice.actions;

const ConnectReducer = ConnectSlice.reducer;
export default ConnectReducer;
